import { Suspense, useEffect } from "react";

import { Await, useLoaderData } from "@remix-run/react";
import type { HeadersFunction, SerializeFrom } from "@remix-run/server-runtime";
import { defer } from "@remix-run/server-runtime";

import { SWR } from "@commerce/.server/caching.server";
import { getContext } from "@commerce/.server/context.server";
import { getTimingContext } from "~/commerce-sap/.server/timing.server";
import { getClient } from "~/contentful/.server/contentful.server";
import { deferDataRequest } from "~/lib/utils-server/http.server";
import { getHomeMeta } from "~/seo/servers/seo.home.server";

//import { Skeletons } from "";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

import { Skeletons } from "~/commerce-sap/routes/($locale)+/_product-list+/components/skeletons";
import type { TypeGenericTemplateSkeleton } from "~/contentful/compiled";
import { useEmarsysEvents } from "~/emarsys/report.emarsys.events";
import { useInsider } from "~/insider";
import { componentsSwitch } from "~/lib/generic-template-helper";
import { cn } from "~/lib/ui";

import { useBasket } from "./resources+/hooks/useBasket";

export const handle: AppHandle = {
  // eslint-disable-next-line no-empty-pattern
  contentRootHeroSlot({}: SerializeFrom<typeof loader>) {
    return <div className={cn("relative flex overflow-hidden")}></div>;
  },
  contentRootBottomSlot(data) {
    return <section className="bg-white py-8"></section>;
  },
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    "cache-control":
      loaderHeaders.get("cache-control") ??
      "s-maxage=600, stale-while-revalidate",
  };
};

export { shouldRevalidate } from "~/lib/default-should-revalidate";

export const loader = async ({ request, context: { locale } }: LoaderArgs) => {
  /* Caching template */
  const swr = SWR("homepage-index");

  const url = new URL(request.url);
  const preview = !!url.searchParams.get("preview");
  const client = getClient(preview);
  const { time } = getTimingContext();

  const homePageContent = await swr(async () => {
    return await client.getEntry<TypeGenericTemplateSkeleton>(
      "TDsFaOrs42dqHI3p3MRIL",
      { include: 5 },
    );
  }, []);

  /* this handles index being called for all non-locale urls e.g. /something-that-does-not-exist  */
  if (
    getContext().locale.id === "default" &&
    new URL(request.url).pathname !== "/ab/"
  ) {
    throw new Response("Not Found", { status: 404 });
  }

  //TODO: Logic to preload LCP Image from banner

  const [seo] = await time(
    deferDataRequest(
      Promise.resolve(homePageContent).then(data => {
        const seoData = data.fields.seo;
        return getHomeMeta({
          type: "home",
          options: {
            url: request.url,
            h1: seoData?.fields.h1,
            title: seoData?.fields.title,
            description: seoData?.fields.description,
            lang: locale.alias,
          },
          robots: {
            noFollow: seoData?.fields.followLinks === "nofollow" ? true : false,
            noIndex:
              seoData?.fields.indexedBySearchEngines === "noindex"
                ? true
                : false,
          },
          og: {
            type: seoData?.fields.ogtype,
            title: seoData?.fields.ogtitle,
            description: seoData?.fields.ogdescription,
            url: seoData?.fields.ogurl,
            image: seoData?.fields.ogimage,
          },
        });
      }),
    ),
    {
      type: "seo",
      desc: "SEO",
    },
  );

  return defer(
    {
      homePageContent,
      seo,
      preview,
    },
    {
      headers: {
        "Cache-Control": "s-maxage=30, stale-while-revalidate=86400",
      },
    },
  );
};

export default function IndexPage() {
  const { homePageContent, preview } = useLoaderData<typeof loader>();
  const liveHomePageContent = useContentfulLiveUpdates(homePageContent);
  const homePageContentData = preview ? liveHomePageContent : homePageContent;
  const { setInsiderPageType } = useInsider();
  const basket = useBasket();
  const sendEmarsysReport = useEmarsysEvents();

  useEffect(() => {
    setInsiderPageType("Home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (basket) {
      sendEmarsysReport([{ eventType: "cart", eventData: basket }]);
    }
  }, [basket, sendEmarsysReport]);

  return (
    <>
      <section className="mt-nav bg-white">
        <div className="flex flex-wrap items-center pb-12">
          <nav id="store" className="top-0 w-full ">
            <div className="flex w-full flex-col gap-10 pb-12 ">
              <Suspense fallback={<Skeletons />}>
                <Await resolve={homePageContentData}>
                  {pageContent => {
                    return (
                      <div className="flex flex-col gap-10 overflow-hidden lg:gap-16 xl:gap-20">
                        {pageContent?.fields?.components.map(
                          (content, index) => {
                            return componentsSwitch(
                              content.sys.contentType.sys.id,
                              content,
                              index,
                            );
                          },
                        )}
                      </div>
                    );
                  }}
                </Await>
              </Suspense>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
}
